.group {
  position:relative;
}

#myInput:focus::-webkit-input-placeholder {
  visibility:hidden; /*hide placeholder on focus*/
}

#myInput {
  padding: 5px;
  padding-left: 12px;
  transition: all 0.2s ease-in-out;

}

#myInput:focus {
  border: 1px solid dodgerblue;
  border-radius:6px;
  transition: all 0.2s ease-in-out;

}

#myInput-label {
  position: absolute;
  left: 8px;
  top: 50%;
  font-size:16px;
  color:dodgerblue;
  font-family:sans-serif;
  background-color: #fff;
  padding: 0 3px;
  transform: translateY(-50%);
}



/*style of label when input is focused*/
#myInput:focus + #myInput-label  {
  display: inline-block;
  position:absolute;
  left: 15px;
  font-size: 12px;
  top: 0px;
  transition: all 0.2s ease-in-out;
}
